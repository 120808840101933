(function () {
  'use strict';
  const app = {
    init() {
      svg4everybody();
  
      this.stickyHeader();
      this.toggler();
      this.modals();
      this.slider();
      this.slGallery();
      this.gallery();
      this.galleryPost();
      this.calc();
      this.yaMap();
    },
  
    blockScroll(status = false) {
      if (!status) {
        document.documentElement.classList.remove('is-no-scroll');
        return;
      }
  
      document.documentElement.classList.add('is-no-scroll');
    },
  
    stickyHeader() {
      let el = document.querySelector('#header');
  
      if (!el) return;
  
      el.style.height = el.offsetHeight + 'px';
  
      zenscroll.setup( 500, (el.offsetHeight + 15) );
  
      const headroom = new Headroom(el, {
        offset: (el.offsetHeight + 15),
        classes: {
          pinned: 'is-pinned',
          unpinned: 'is-unpinned',
          top: 'is-top',
          notTop: 'is-not-top',
          bottom: 'is-bottom',
          notBottom: 'is-not-bottom',
        },
        onNotTop: () => {
        },
        onTop: () => {
        }
      });
  
      headroom.init();
    },
  
    toggler() {
      new Unitoggle({
        onOpen: tab => {
          if ( tab.id == 'header-bottom' ) {
            this.blockScroll(true);
          }
        }, // After open callback
        onClose: tab => {
          this.blockScroll(false);
        } // After close callback
      });
    },
  
    modals() {
      new Unimodal({
        onOpen: ( modal, button ) => {
          /* ... do your staff here ... */
        },
        onClose: modal => {
          /* ... do your staff here ... */
        }
      });
    },
  
    slider() {
      const elems = document.querySelectorAll('.js-slider');
      if (!elems.length) return;
  
      elems.forEach(el => {
        const prev = el.querySelector('.js-prev');
        const next = el.querySelector('.js-next');
        const pagination = el.querySelector('.js-pagination');
  
        new Swiper(el, {
          slidesPerView: 'auto',
          watchOverflow: true,
          loop: true,
          loopedSlides: 2,
          autoplay: {
            delay: 5000,
          },
          navigation: {
            prevEl: (prev) ? prev : null,
            nextEl: (next) ? next : null,
            disabledClass: 'is-disabled',
            lockClass: 'is-lock',
          },
          pagination: {
            el: (pagination) ? pagination : null,
            type: 'bullets',
            bulletClass: 'bullet',
            bulletActiveClass: 'is-active',
            clickable: true,
            bulletElement: 'button',
            currentClass: 'is-current',
          },
        });
      });
    },
  
    slGallery() {
      const elems = document.querySelectorAll('.js-sl-gallery');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        const sl = el.querySelector('.js-sl');
  
        const prevBtn = el.querySelector('.js-prev');
        const nextBtn = el.querySelector('.js-next');
        const pagination = el.querySelector('.js-pagination');
        const paginationFraction = el.querySelector('.js-pagination-fraction');
  
        let paginationOpts = {};
  
        if (pagination) {
          paginationOpts = {
            el: pagination,
            type: 'bullets',
            bulletClass: 'bullet',
            bulletActiveClass: 'is-active',
            clickable: true,
            currentClass: 'is-current'
          };
        }
  
        if (paginationFraction) {
          paginationOpts = {
            el: paginationFraction,
            type: 'fraction',
            currentClass: 'is-current',
            totalClass: 'is-total'
          };
        }
  
        new Swiper(sl, {
          slidesPerView: 'auto',
          navigation: {
            prevEl: prevBtn,
            nextEl: nextBtn,
            disabledClass: 'is-disabled'
          },
          pagination: paginationOpts,
          breakpoints: {
            0: {
              centeredSlides: true
            },
            768: {
              centeredSlides: false
            }
          }
        });
      });
    },
  
    gallery() {
      let elems = document.querySelectorAll('.js-gallery');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        lightGallery(el, {
          selector: '.js-link',
          download: false
        });
      });
    },
  
    galleryPost() {
      const el = document.querySelector('.js-post-single');
  
      if (!el) return;
  
      const elems = el.querySelectorAll('.gallery');
  
      if (!elems.length) return;
  
      const galleryLinks = el.querySelectorAll('.gallery a');
  
      if (!galleryLinks.length) return;
  
      elems.forEach(one => {
        one.classList.add('js-gallery');
  
        const links = one.querySelectorAll('a');
  
        links.forEach(link => {
          link.classList.add('js-link');
        });
      });
  
      this.gallery();
    },
  
    calc() {
      const el = document.querySelector('#calc');
  
      if ( !el ) return;
  
      const fields = Array.from( el.querySelectorAll('.js-input') );
  
      const total_calc = () => {
        let persons_all = 0;
        let total_all = 0;
  
        const el_total = el.querySelector('.js-total-calc');
        const input_total = el.querySelector('.js-input-total-calc');
  
        fields.forEach(el => {
          const price = parseInt( el.getAttribute('data-price') );
          const count = parseInt( el.value );
  
          const total = total_row(price, count);
  
          total_all += total;
          persons_all += count;
        });
  
        el_total.innerHTML = `${persons_all} чел., ${total_all} руб.`;
        input_total.value = `${persons_all} чел., ${total_all} руб.`;
      };
  
      const total_row = (price, count) => {
        let one_price = 0;
  
        one_price = price;
  
        if ( count > 5 ) {
          one_price = price - (price * 0.15);
        }
  
        if ( count > 10 ) {
          one_price = price - (price * 0.30);
        }
  
        const total = one_price * count;
  
        return total;
      }
  
      fields.forEach(el => {
        el.addEventListener('change', e => {
          const price = parseInt( e.target.getAttribute('data-price') );
          const count = parseInt( e.target.value );
  
          const row = e.target.closest('.js-row');
          const input_total = row.querySelector('.js-input-total');
          const el_total = row.querySelector('.js-total');
  
          const total = total_row(price, count);
  
          if ( total == 0 ) {
            input_total.value = 0;
            el_total.innerHTML = '';
          }
  
          if ( total > 0 ) {
            input_total.value = total;
            el_total.innerHTML = `${total} руб.`;
          }
  
          total_calc();
        });
      });
    },
  
    yaMap() {
      let el = document.querySelector('#map');
  
      if (!el) return;
  
      ymaps.ready(() => {
        let coords = JSON.parse(el.dataset.coords);
  
        let myMap = new ymaps.Map(el.id, {
          center: coords, // Координаты центра карты
          zoom: 15, // от 0 (весь мир) до 19
          controls: ['typeSelector', 'fullscreenControl']
        });
  
        myMap.behaviors.disable(['scrollZoom']);
  
        let myPlacemark = new ymaps.Placemark(coords, {}, {
          iconLayout: 'default#image',
          iconImageHref: el.dataset.mark,
          iconImageSize: [56, 80],
          iconImageOffset: [-28, -80]
        });
  
        myMap.geoObjects.add(myPlacemark);
      });
    }
  };
  
  const quiz = {
    init() {
      const steps_wrapper = document.querySelector('.js-screen-steps');
      if (!steps_wrapper) return;
  
      this.steps = document.querySelectorAll('[data-step]');
      this.step = 0;
      this.stepsCount = +steps_wrapper.getAttribute('data-steps');
  
      this.btn_next = steps_wrapper.querySelector('.js-btn-next');
      this.btn_prev = steps_wrapper.querySelector('.js-btn-prev');
      this.btn_submit = steps_wrapper.querySelector('.js-btn-submit');
      this.inputs = steps_wrapper.querySelectorAll('.js-input');
      this.inputsContact = steps_wrapper.querySelectorAll('.js-input-contact');
  
      this.switchStep(this.steps, this.step);
  
      this.inputs.forEach(input => {
        if (['radio', 'checkbox'].includes(input.type)) {
          input.addEventListener('change', e => {
            if (input.type == 'radio') {
              this.btn_next.removeAttribute('disabled');
            }
            if (input.type == 'checkbox') {
              const activeCbx = Array.from(this.inputs).filter(el => {
                return (el.type == 'checkbox' && el.checked) ? true : false;
              });
              if (activeCbx.length > 0) {
                this.btn_next.removeAttribute('disabled');
              } else {
                this.btn_next.setAttribute('disabled', true);
              }
            }
  
            if ( input.classList.contains('js-input-end') ) {
              if ( ['whatsapp', 'tel'].includes(input.dataset.field) ) {
                this.setInputContact('tel');
              }
              if ( ['email'].includes(input.dataset.field) ) {
                this.setInputContact('email');
              }
            }
          });
        }
  
        if (input.type == 'text') {
          input.addEventListener('keyup', LodashDebounce(() => {
            const inputCbx = input.closest('.ui-cbx').querySelector('.js-input');
  
            if (input.value != '') {
              inputCbx.setAttribute('checked', true);
  
              if (inputCbx.type == 'checkbox') {
                const activeCbx = Array.from(this.inputs).filter(el => {
                  return (el.type == 'checkbox' && el.checked) ? true : false;
                });
  
                if (activeCbx.length > 0) {
                  const el = input.closest('[data-step]');
                  const idx = +el.getAttribute('data-step');
                  this.step = idx + 1;
                  this.btn_next.removeAttribute('disabled');
                } else {
                  this.btn_next.setAttribute('disabled', true);
                }
              }
  
              return;
            }
  
            inputCbx.removeAttribute('checked');
          }, 200));
        }
      });
  
      this.btn_next.addEventListener('click', e => {
        if (!this.btn_next.getAttribute('disabled')) {
          this.step++;
  
          if ( this.step == (this.stepsCount + 1) ) {
            return;
          }
  
          this.switchStep(this.steps, this.step);
        }
      });
  
      this.btn_prev.addEventListener('click', e => {
        if (!this.btn_prev.getAttribute('disabled')) {
          this.step--;
  
          if (this.step < 0) {
            this.step = 0;
            return;
          }
  
          this.switchStep(this.steps, this.step);
        }
      });
    },
  
    switchStep(steps, step_id) {
      steps.forEach(el => el.classList.remove('is-active'));
  
      steps[step_id].classList.add('is-active');
  
      this.btn_next.setAttribute('disabled', true);
  
      const checked = steps[step_id].querySelector('input:checked');
  
      if (checked) {
        this.btn_next.removeAttribute('disabled');
      }
  
      this.btn_prev.setAttribute('disabled',true);
      this.btn_prev.classList.remove('is-active');
  
      if (step_id > 0) {
        this.btn_prev.removeAttribute('disabled');
        this.btn_prev.classList.add('is-active');
      }
  
      if ( step_id == (this.stepsCount - 1) ) {
        this.btn_next.classList.remove('is-active');
        this.btn_submit.classList.add('is-active');
      }
  
      if ( step_id != (this.stepsCount - 1) ) {
        this.btn_next.classList.add('is-active');
        this.btn_submit.classList.remove('is-active');
      }
  
      this.setInputContact();
    },
  
    getIdYm() {
      const el = document.querySelector('[data-ym]');
  
      const id = el.getAttribute('data-ym');
  
      if (id) {
        return id;
      }
  
      return false;
    },
  
    setInputContact(type = '') {
      this.inputsContact.forEach(input => {
        input.removeAttribute('required');
        input.classList.remove('is-active');
      });
  
      if ( !type ) return;
  
      this.inputsContact.forEach(input => {
        if ( type == 'tel' && input.name == 'tel' ) {
          input.setAttribute('required', true);
          input.classList.add('is-active');
          return;
        }
        if ( type == 'email' && input.name == 'email' ) {
          input.setAttribute('required', true);
          input.classList.add('is-active');
          return;
        }
      });
    }
  
  };
  
  const form = {
    init() {
      this.inputTel();
  
      this.setup();
      this.validation();
      this.sending();
    },
  
    inputTel() {
      // https://github.com/uNmAnNeR/imaskjs
      const elems = document.querySelectorAll('.js-masked');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        let mask = IMask(el, {
          mask: el.dataset.mask
          // lazy: false
        });
  
        el.addEventListener('focus', e => {
          mask.updateOptions({ lazy: false });
        });
  
        el.addEventListener('blur', e => {
          mask.updateOptions({ lazy: true });
        });
      });
    },
  
    setup() {
      this.bouncerSettings = {
        messageAfterField: true,
        messages: {
          missingValue: {
            checkbox: 'Обязательное поле',
            radio: 'Выберите значение',
            select: 'Выберите значение',
            'select-multiple': 'Выберите значение',
            default: 'Обязательное поле'
          },
          patternMismatch: {
            email: 'Не верный формат e-mail',
            default: 'Проверьте формат значения'
          },
          phoneNum: 'Введите верный номер'
        },
        disableSubmit: true
      }
    },
  
    validation() {
      if (typeof Bouncer === 'undefined') return;
  
      window.AppBouncer = new Bouncer('[data-bouncer]', this.bouncerSettings);
  
      document.addEventListener('bouncerRemoveError', e => {
        const field = e.target;
        field.classList.add('is-valid');
      }, false);
  
      document.addEventListener('bouncerShowError', e => {
        const field = e.target;
        field.classList.remove('is-valid');
      }, false);
    },
  
    sending() {
      document.addEventListener('bouncerFormValid', e => {
        const form = e.target;
        const type = form.dataset.type;
  
        if (form.hasAttribute('method')) {
          form.submit();
          return;
        }
  
        const btn = form.querySelector('[type="submit"]');
  
        const url = '/ajax.php';
  
        const fd = new FormData(form);
  
        fd.append('action', form.dataset.action);
  
        form.classList.add('is-process');
        btn.setAttribute('disabled', true);
  
        fetch(url, {
          method: 'POST',
          body: fd
        }).then(response => response.json()).then(res => {
  
          form.classList.remove('is-process');
          btn.removeAttribute('disabled');
          form.reset();
  
          if (res.success) {
  
            if (typeof ym == 'function') {
              const target = (form.hasAttribute('data-target')) ? form.dataset.target : '';
              const ymid = (form.hasAttribute('data-ym')) ? form.dataset.ym : '';
  
              if ( ymid && target ) {
                ym(ymid, 'reachGoal', target);
              }
            }
  
            form.classList.add('is-success');
  
            if (res.data.url) {
              setTimeout(() => {
                window.location.assign(res.data.url);
              }, 3000);
            }
  
            setTimeout(() => {
              form.classList.remove('is-success');
              Unimodal.closeAll();
            }, 3000);
          }
  
          if (!res.success) {
            form.classList.add('is-error');
  
            setTimeout(() => {
              form.classList.remove('is-error');
            }, 3000);
  
            console.error(res);
          }
  
        });
      }, false);
    }
  };
  

  document.addEventListener('DOMContentLoaded', () => {
    app.init();
    quiz.init();
    form.init();
  });
}());
