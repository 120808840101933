const quiz = {
  init() {
    const steps_wrapper = document.querySelector('.js-screen-steps');
    if (!steps_wrapper) return;

    this.steps = document.querySelectorAll('[data-step]');
    this.step = 0;
    this.stepsCount = +steps_wrapper.getAttribute('data-steps');

    this.btn_next = steps_wrapper.querySelector('.js-btn-next');
    this.btn_prev = steps_wrapper.querySelector('.js-btn-prev');
    this.btn_submit = steps_wrapper.querySelector('.js-btn-submit');
    this.inputs = steps_wrapper.querySelectorAll('.js-input');
    this.inputsContact = steps_wrapper.querySelectorAll('.js-input-contact');

    this.switchStep(this.steps, this.step);

    this.inputs.forEach(input => {
      if (['radio', 'checkbox'].includes(input.type)) {
        input.addEventListener('change', e => {
          if (input.type == 'radio') {
            this.btn_next.removeAttribute('disabled');
          }
          if (input.type == 'checkbox') {
            const activeCbx = Array.from(this.inputs).filter(el => {
              return (el.type == 'checkbox' && el.checked) ? true : false;
            });
            if (activeCbx.length > 0) {
              this.btn_next.removeAttribute('disabled');
            } else {
              this.btn_next.setAttribute('disabled', true);
            }
          }

          if ( input.classList.contains('js-input-end') ) {
            if ( ['whatsapp', 'tel'].includes(input.dataset.field) ) {
              this.setInputContact('tel');
            }
            if ( ['email'].includes(input.dataset.field) ) {
              this.setInputContact('email');
            }
          }
        });
      }

      if (input.type == 'text') {
        input.addEventListener('keyup', LodashDebounce(() => {
          const inputCbx = input.closest('.ui-cbx').querySelector('.js-input');

          if (input.value != '') {
            inputCbx.setAttribute('checked', true);

            if (inputCbx.type == 'checkbox') {
              const activeCbx = Array.from(this.inputs).filter(el => {
                return (el.type == 'checkbox' && el.checked) ? true : false;
              });

              if (activeCbx.length > 0) {
                const el = input.closest('[data-step]');
                const idx = +el.getAttribute('data-step');
                this.step = idx + 1;
                this.btn_next.removeAttribute('disabled');
              } else {
                this.btn_next.setAttribute('disabled', true);
              }
            }

            return;
          }

          inputCbx.removeAttribute('checked');
        }, 200));
      }
    });

    this.btn_next.addEventListener('click', e => {
      if (!this.btn_next.getAttribute('disabled')) {
        this.step++;

        if ( this.step == (this.stepsCount + 1) ) {
          return;
        }

        this.switchStep(this.steps, this.step);
      }
    });

    this.btn_prev.addEventListener('click', e => {
      if (!this.btn_prev.getAttribute('disabled')) {
        this.step--;

        if (this.step < 0) {
          this.step = 0;
          return;
        }

        this.switchStep(this.steps, this.step);
      }
    });
  },

  switchStep(steps, step_id) {
    steps.forEach(el => el.classList.remove('is-active'));

    steps[step_id].classList.add('is-active');

    this.btn_next.setAttribute('disabled', true);

    const checked = steps[step_id].querySelector('input:checked');

    if (checked) {
      this.btn_next.removeAttribute('disabled');
    }

    this.btn_prev.setAttribute('disabled',true);
    this.btn_prev.classList.remove('is-active');

    if (step_id > 0) {
      this.btn_prev.removeAttribute('disabled');
      this.btn_prev.classList.add('is-active');
    }

    if ( step_id == (this.stepsCount - 1) ) {
      this.btn_next.classList.remove('is-active');
      this.btn_submit.classList.add('is-active');
    }

    if ( step_id != (this.stepsCount - 1) ) {
      this.btn_next.classList.add('is-active');
      this.btn_submit.classList.remove('is-active');
    }

    this.setInputContact();
  },

  getIdYm() {
    const el = document.querySelector('[data-ym]');

    const id = el.getAttribute('data-ym');

    if (id) {
      return id;
    }

    return false;
  },

  setInputContact(type = '') {
    this.inputsContact.forEach(input => {
      input.removeAttribute('required');
      input.classList.remove('is-active');
    });

    if ( !type ) return;

    this.inputsContact.forEach(input => {
      if ( type == 'tel' && input.name == 'tel' ) {
        input.setAttribute('required', true);
        input.classList.add('is-active');
        return;
      }
      if ( type == 'email' && input.name == 'email' ) {
        input.setAttribute('required', true);
        input.classList.add('is-active');
        return;
      }
    });
  }

};
