const app = {
  init() {
    svg4everybody();

    this.stickyHeader();
    this.toggler();
    this.modals();
    this.slider();
    this.slGallery();
    this.gallery();
    this.galleryPost();
    this.calc();
    this.yaMap();
  },

  blockScroll(status = false) {
    if (!status) {
      document.documentElement.classList.remove('is-no-scroll');
      return;
    }

    document.documentElement.classList.add('is-no-scroll');
  },

  stickyHeader() {
    let el = document.querySelector('#header');

    if (!el) return;

    el.style.height = el.offsetHeight + 'px';

    zenscroll.setup( 500, (el.offsetHeight + 15) );

    const headroom = new Headroom(el, {
      offset: (el.offsetHeight + 15),
      classes: {
        pinned: 'is-pinned',
        unpinned: 'is-unpinned',
        top: 'is-top',
        notTop: 'is-not-top',
        bottom: 'is-bottom',
        notBottom: 'is-not-bottom',
      },
      onNotTop: () => {
      },
      onTop: () => {
      }
    });

    headroom.init();
  },

  toggler() {
    new Unitoggle({
      onOpen: tab => {
        if ( tab.id == 'header-bottom' ) {
          this.blockScroll(true);
        }
      }, // After open callback
      onClose: tab => {
        this.blockScroll(false);
      } // After close callback
    });
  },

  modals() {
    new Unimodal({
      onOpen: ( modal, button ) => {
        /* ... do your staff here ... */
      },
      onClose: modal => {
        /* ... do your staff here ... */
      }
    });
  },

  slider() {
    const elems = document.querySelectorAll('.js-slider');
    if (!elems.length) return;

    elems.forEach(el => {
      const prev = el.querySelector('.js-prev');
      const next = el.querySelector('.js-next');
      const pagination = el.querySelector('.js-pagination');

      new Swiper(el, {
        slidesPerView: 'auto',
        watchOverflow: true,
        loop: true,
        loopedSlides: 2,
        autoplay: {
          delay: 5000,
        },
        navigation: {
          prevEl: (prev) ? prev : null,
          nextEl: (next) ? next : null,
          disabledClass: 'is-disabled',
          lockClass: 'is-lock',
        },
        pagination: {
          el: (pagination) ? pagination : null,
          type: 'bullets',
          bulletClass: 'bullet',
          bulletActiveClass: 'is-active',
          clickable: true,
          bulletElement: 'button',
          currentClass: 'is-current',
        },
      });
    });
  },

  slGallery() {
    const elems = document.querySelectorAll('.js-sl-gallery');

    if (!elems.length) return;

    elems.forEach(el => {
      const sl = el.querySelector('.js-sl');

      const prevBtn = el.querySelector('.js-prev');
      const nextBtn = el.querySelector('.js-next');
      const pagination = el.querySelector('.js-pagination');
      const paginationFraction = el.querySelector('.js-pagination-fraction');

      let paginationOpts = {};

      if (pagination) {
        paginationOpts = {
          el: pagination,
          type: 'bullets',
          bulletClass: 'bullet',
          bulletActiveClass: 'is-active',
          clickable: true,
          currentClass: 'is-current'
        };
      }

      if (paginationFraction) {
        paginationOpts = {
          el: paginationFraction,
          type: 'fraction',
          currentClass: 'is-current',
          totalClass: 'is-total'
        };
      }

      new Swiper(sl, {
        slidesPerView: 'auto',
        navigation: {
          prevEl: prevBtn,
          nextEl: nextBtn,
          disabledClass: 'is-disabled'
        },
        pagination: paginationOpts,
        breakpoints: {
          0: {
            centeredSlides: true
          },
          768: {
            centeredSlides: false
          }
        }
      });
    });
  },

  gallery() {
    let elems = document.querySelectorAll('.js-gallery');

    if (!elems.length) return;

    elems.forEach(el => {
      lightGallery(el, {
        selector: '.js-link',
        download: false
      });
    });
  },

  galleryPost() {
    const el = document.querySelector('.js-post-single');

    if (!el) return;

    const elems = el.querySelectorAll('.gallery');

    if (!elems.length) return;

    const galleryLinks = el.querySelectorAll('.gallery a');

    if (!galleryLinks.length) return;

    elems.forEach(one => {
      one.classList.add('js-gallery');

      const links = one.querySelectorAll('a');

      links.forEach(link => {
        link.classList.add('js-link');
      });
    });

    this.gallery();
  },

  calc() {
    const el = document.querySelector('#calc');

    if ( !el ) return;

    const fields = Array.from( el.querySelectorAll('.js-input') );

    const total_calc = () => {
      let persons_all = 0;
      let total_all = 0;

      const el_total = el.querySelector('.js-total-calc');
      const input_total = el.querySelector('.js-input-total-calc');

      fields.forEach(el => {
        const price = parseInt( el.getAttribute('data-price') );
        const count = parseInt( el.value );

        const total = total_row(price, count);

        total_all += total;
        persons_all += count;
      });

      el_total.innerHTML = `${persons_all} чел., ${total_all} руб.`;
      input_total.value = `${persons_all} чел., ${total_all} руб.`;
    };

    const total_row = (price, count) => {
      let one_price = 0;

      one_price = price;

      if ( count > 5 ) {
        one_price = price - (price * 0.15);
      }

      if ( count > 10 ) {
        one_price = price - (price * 0.30);
      }

      const total = one_price * count;

      return total;
    }

    fields.forEach(el => {
      el.addEventListener('change', e => {
        const price = parseInt( e.target.getAttribute('data-price') );
        const count = parseInt( e.target.value );

        const row = e.target.closest('.js-row');
        const input_total = row.querySelector('.js-input-total');
        const el_total = row.querySelector('.js-total');

        const total = total_row(price, count);

        if ( total == 0 ) {
          input_total.value = 0;
          el_total.innerHTML = '';
        }

        if ( total > 0 ) {
          input_total.value = total;
          el_total.innerHTML = `${total} руб.`;
        }

        total_calc();
      });
    });
  },

  yaMap() {
    let el = document.querySelector('#map');

    if (!el) return;

    ymaps.ready(() => {
      let coords = JSON.parse(el.dataset.coords);

      let myMap = new ymaps.Map(el.id, {
        center: coords, // Координаты центра карты
        zoom: 15, // от 0 (весь мир) до 19
        controls: ['typeSelector', 'fullscreenControl']
      });

      myMap.behaviors.disable(['scrollZoom']);

      let myPlacemark = new ymaps.Placemark(coords, {}, {
        iconLayout: 'default#image',
        iconImageHref: el.dataset.mark,
        iconImageSize: [56, 80],
        iconImageOffset: [-28, -80]
      });

      myMap.geoObjects.add(myPlacemark);
    });
  }
};
